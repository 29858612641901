package nay.kirill.miniApp.engclub.data

import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.http.URLProtocol
import io.ktor.http.path
import nay.kirill.miniApp.engclub.data.models.WordResponse
import nay.kirill.miniApp.engclub.domain.models.AlternativeTranslations
import nay.kirill.miniApp.engclub.domain.models.Word
import nay.kirill.miniApp.engclub.domain.repositories.WordRepository

class WordRepositoryImpl(
    private val client: HttpClient
) : WordRepository {

    override suspend fun getWord(userId: String, id: String): Word {
        val response: WordResponse  = client.get {
            url {
                protocol = URLProtocol.HTTPS
                host = BASE_URL
                path("user", userId, "word", id)
            }
        }
            .body()

        return response.toWord()
    }

    private fun WordResponse.toWord() = Word(
        id = id,
        isSaved = isSaved,
        text = text,
        transcription = transcription,
        translation = translation,
        image = image,
        definition = definition,
        examples = examples,
        meaningsWithSimilarTranslation = meaningsWithSimilarTranslation.map {
            AlternativeTranslations(
                id = it.id,
                translation = it.translation
            )
        }
    )

    companion object {

        private const val BASE_URL = "englishclub-bot.ru/api"

    }
}