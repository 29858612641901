package nay.kirill.miniApp.engclub

import cafe.adriel.voyager.core.registry.ScreenRegistry
import com.kirillNay.telegram.miniapp.compose.telegramWebApp
import com.kirillNay.telegram.miniapp.webApp.webApp
import nay.kirill.miniApp.engclub.data.dataModule
import nay.kirill.miniApp.engclub.di.appModule
import nay.kirill.miniApp.engclub.features.notFound.notFoundScreenModule
import nay.kirill.miniApp.engclub.features.wordDetails.wordDetailsModule
import nay.kirill.miniApp.engclub.features.wordDetails.wordDetailsScreenModule
import nay.kirill.miniApp.engclub.navigation.MainNavigationScreen
import nay.kirill.miniApp.engclub.theme.EnglishClubTheme
import org.koin.core.context.startKoin

fun main() {
    startKoin {
        modules(appModule, wordDetailsModule, dataModule)
    }
    setupNavigation()

    telegramWebApp { style ->
        EnglishClubTheme {
            MainNavigationScreen(initData = webApp.initDataUnsafe.startParam, style = style)
        }
    }
}

private fun setupNavigation() {
    ScreenRegistry {
        wordDetailsScreenModule()
        notFoundScreenModule()
    }
}