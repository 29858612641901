@file:Suppress("detekt.LongParameterList", "detekt.LongMethod")

package nay.kirill.miniApp.engclub.theme

import androidx.compose.runtime.Composable
import androidx.compose.runtime.compositionLocalOf
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.sp
import nay.kirill.app.generated.resources.Res
import nay.kirill.app.generated.resources.Roboto_Bold
import nay.kirill.app.generated.resources.Roboto_Regular
import nay.kirill.app.generated.resources.SF_Pro_Text_Bold
import nay.kirill.app.generated.resources.SF_Pro_Text_Regular
import nay.kirill.app.generated.resources.SF_Pro_Text_Semibold
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.Font

class Typography internal constructor(
    // Title
    val largeTitleRegular: TextStyle,
    val largeTitleSemibold: TextStyle,
    val largeTitleBold: TextStyle,

    val title1Regular: TextStyle,
    val title1Semibold: TextStyle,
    val title1Bold: TextStyle,

    val title2Regular: TextStyle,
    val title2Semibold: TextStyle,
    val title2Bold: TextStyle,

    val title3Regular: TextStyle,
    val title3Semibold: TextStyle,
    val title3Bold: TextStyle,

    // Headline
    val headlineRegular: TextStyle,
    val headlineSemibold: TextStyle,
    val headlineBold: TextStyle,

    val textRegular: TextStyle,
    val textSemibold: TextStyle,
    val textBold: TextStyle,

    val subheadline1Regular: TextStyle,
    val subheadline1Semibold: TextStyle,
    val subheadline1Bold: TextStyle,

    val subheadline2Regular: TextStyle,
    val subheadline2Semibold: TextStyle,
    val subheadline2Bold: TextStyle,

    // Caption
    val caption1Regular: TextStyle,
    val caption1Semibold: TextStyle,
    val caption1Bold: TextStyle,

    val caption2Regular: TextStyle,
    val caption2Semibold: TextStyle,
    val caption2Bold: TextStyle
)

fun typography(
    default: TextStyle,
) = Typography(
    largeTitleRegular = default.copy(
        fontWeight = FontWeight.Normal,
        fontSize = 34.sp,
        letterSpacing = 0.25.sp,
        lineHeight = 42.sp
    ),
    largeTitleSemibold = default.copy(
        fontWeight = FontWeight.SemiBold,
        fontSize = 34.sp,
        letterSpacing = 0.25.sp,
        lineHeight = 42.sp
    ),
    largeTitleBold = default.copy(
        fontWeight = FontWeight.Bold,
        fontSize = 34.sp,
        letterSpacing = 0.25.sp,
        lineHeight = 42.sp
    ),

    title1Regular = default.copy(
        fontWeight = FontWeight.Normal,
        fontSize = 28.sp,
        letterSpacing = 0.sp,
        lineHeight = 36.sp
    ),
    title1Semibold = default.copy(
        fontWeight = FontWeight.SemiBold,
        fontSize = 28.sp,
        letterSpacing = 0.sp,
        lineHeight = 36.sp
    ),
    title1Bold = default.copy(
        fontWeight = FontWeight.Bold,
        fontSize = 28.sp,
        letterSpacing = 0.sp,
        lineHeight = 36.sp
    ),

    title2Regular = default.copy(
        fontWeight = FontWeight.Normal,
        fontSize = 24.sp,
        letterSpacing = 0.sp,
        lineHeight = 32.sp
    ),
    title2Semibold = default.copy(
        fontWeight = FontWeight.SemiBold,
        fontSize = 24.sp,
        letterSpacing = 0.sp,
        lineHeight = 32.sp
    ),
    title2Bold = default.copy(
        fontWeight = FontWeight.Bold,
        fontSize = 24.sp,
        letterSpacing = 0.sp,
        lineHeight = 32.sp
    ),

    title3Regular = default.copy(
        fontWeight = FontWeight.Normal,
        fontSize = 20.sp,
        letterSpacing = 0.15.sp,
        lineHeight = 24.sp
    ),
    title3Semibold = default.copy(
        fontWeight = FontWeight.SemiBold,
        fontSize = 20.sp,
        letterSpacing = 0.15.sp,
        lineHeight = 24.sp
    ),
    title3Bold = default.copy(
        fontWeight = FontWeight.Bold,
        fontSize = 20.sp,
        letterSpacing = 0.15.sp,
        lineHeight = 24.sp
    ),

    headlineRegular = default.copy(
        fontWeight = FontWeight.Normal,
        fontSize = 19.sp,
        letterSpacing = 0.15.sp,
        lineHeight = 28.sp
    ),
    headlineSemibold = default.copy(
        fontWeight = FontWeight.SemiBold,
        fontSize = 19.sp,
        letterSpacing = 0.15.sp,
        lineHeight = 28.sp
    ),
    headlineBold = default.copy(
        fontWeight = FontWeight.Bold,
        fontSize = 19.sp,
        letterSpacing = 0.15.sp,
        lineHeight = 28.sp
    ),

    textRegular = default.copy(
        fontWeight = FontWeight.Normal,
        fontSize = 17.sp,
        letterSpacing = 0.1.sp,
        lineHeight = 26.sp
    ),
    textSemibold = default.copy(
        fontWeight = FontWeight.SemiBold,
        fontSize = 17.sp,
        letterSpacing = 0.1.sp,
        lineHeight = 26.sp
    ),
    textBold = default.copy(
        fontWeight = FontWeight.Bold,
        fontSize = 17.sp,
        letterSpacing = 0.1.sp,
        lineHeight = 26.sp
    ),

    subheadline1Regular = default.copy(
        fontWeight = FontWeight.Normal,
        fontSize = 16.sp,
        letterSpacing = 0.15.sp,
        lineHeight = 24.sp
    ),
    subheadline1Semibold = default.copy(
        fontWeight = FontWeight.SemiBold,
        fontSize = 16.sp,
        letterSpacing = 0.15.sp,
        lineHeight = 24.sp
    ),
    subheadline1Bold = default.copy(
        fontWeight = FontWeight.Bold,
        fontSize = 16.sp,
        letterSpacing = 0.15.sp,
        lineHeight = 24.sp
    ),

    subheadline2Regular = default.copy(
        fontWeight = FontWeight.Normal,
        fontSize = 15.sp,
        letterSpacing = 0.15.sp,
        lineHeight = 22.sp
    ),
    subheadline2Semibold = default.copy(
        fontWeight = FontWeight.SemiBold,
        fontSize = 15.sp,
        letterSpacing = 0.15.sp,
        lineHeight = 22.sp
    ),
    subheadline2Bold = default.copy(
        fontWeight = FontWeight.Bold,
        fontSize = 15.sp,
        letterSpacing = 0.15.sp,
        lineHeight = 22.sp
    ),

    caption1Regular = default.copy(
        fontWeight = FontWeight.Normal,
        fontSize = 13.sp,
        letterSpacing = 0.1.sp,
        lineHeight = 20.sp
    ),
    caption1Semibold = default.copy(
        fontWeight = FontWeight.SemiBold,
        fontSize = 13.sp,
        letterSpacing = 0.1.sp,
        lineHeight = 20.sp
    ),
    caption1Bold = default.copy(
        fontWeight = FontWeight.Bold,
        fontSize = 13.sp,
        letterSpacing = 0.1.sp,
        lineHeight = 20.sp
    ),

    caption2Regular = default.copy(
        fontWeight = FontWeight.Normal,
        fontSize = 10.sp,
        letterSpacing = 0.1.sp,
        lineHeight = 16.sp
    ),
    caption2Semibold = default.copy(
        fontWeight = FontWeight.SemiBold,
        fontSize = 10.sp,
        letterSpacing = 0.1.sp,
        lineHeight = 16.sp
    ),
    caption2Bold = default.copy(
        fontWeight = FontWeight.Bold,
        fontSize = 10.sp,
        letterSpacing = 0.1.sp,
        lineHeight = 16.sp
    )
)

@Composable
fun androidWindowsTypography() = typography(
    default = TextStyle(
        fontFamily = AndroidWindowsFontFamily()
    )
)

@Composable
fun macosTypography() = typography(
    default = TextStyle(
        fontFamily = MacOSFontFamily()
    )
)

@OptIn(ExperimentalResourceApi::class)
@Composable
private fun AndroidWindowsFontFamily() = FontFamily(
    Font(Res.font.Roboto_Regular, weight = FontWeight.Normal),
    Font(Res.font.Roboto_Bold, weight = FontWeight.Bold)
)

@OptIn(ExperimentalResourceApi::class)
@Composable
private fun MacOSFontFamily() = FontFamily(
    Font(Res.font.SF_Pro_Text_Regular, weight = FontWeight.Normal),
    Font(Res.font.SF_Pro_Text_Semibold, weight = FontWeight.Bold),
    Font(Res.font.SF_Pro_Text_Bold, weight = FontWeight.Bold)
)

val TelegramTypography = compositionLocalOf { typography(TextStyle.Default) }

val telegramTypography
    @Composable
    get() = TelegramTypography.current