package nay.kirill.miniApp.engclub.features.notFound

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.core.registry.screenModule
import cafe.adriel.voyager.core.screen.Screen
import com.kirillNay.telegram.miniapp.webApp.webApp
import nay.kirill.miniApp.engclub.core.images.AsyncImage
import nay.kirill.miniApp.engclub.navigation.SharedScreens
import nay.kirill.miniApp.engclub.res.Res
import nay.kirill.miniApp.engclub.theme.telegramTypography

val notFoundScreenModule = screenModule {
    register<SharedScreens.NotFound> {
        NotFoundScreen()
    }
}

private class NotFoundScreen : Screen {

    private val mainButtonClickedListener = {
        webApp.openTelegramLink("https://t.me/LandSpeakingClubBot")
    }

    @Composable
    override fun Content() {
        DisposableEffect(true) {
            webApp.mainButton.apply {
                setText(Res.string.pageNotFoundButton)
                show()
            }

            webApp.mainButton.onClick(mainButtonClickedListener)

            onDispose {
                webApp.mainButton.offClick(mainButtonClickedListener)
            }
        }

        NotFoundPlaceholder(
            modifier = Modifier.fillMaxSize()
        )
    }

    @Composable
    private fun NotFoundPlaceholder(
        modifier: Modifier = Modifier
    ) {
        Column(
            modifier = modifier
                .padding(vertical = 26.dp)
        ) {
            AsyncImage(
                filePath = "files/error_placeholder.jpg",
                contentDescription = "Not found",
                modifier = Modifier
                    .align(Alignment.CenterHorizontally)
                    .size(250.dp)
            )
            Spacer(modifier = Modifier.height(18.dp))
            Text(
                text = Res.string.pageNotFoundTitle,
                style = telegramTypography.title3Bold,
                modifier = Modifier.align(Alignment.CenterHorizontally)
            )
        }
    }
}