@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package nay.kirill.app.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.FontResource

@ExperimentalResourceApi
private object Font0 {
  public val Roboto_Bold: FontResource = org.jetbrains.compose.resources.FontResource(
        "font:Roboto_Bold",
          setOf(
            org.jetbrains.compose.resources.ResourceItem(setOf(), "font/Roboto-Bold.ttf"),
          )
      )

  public val Roboto_Regular: FontResource = org.jetbrains.compose.resources.FontResource(
        "font:Roboto_Regular",
          setOf(
            org.jetbrains.compose.resources.ResourceItem(setOf(), "font/Roboto-Regular.ttf"),
          )
      )

  public val SF_Pro_Text_Bold: FontResource = org.jetbrains.compose.resources.FontResource(
        "font:SF_Pro_Text_Bold",
          setOf(
            org.jetbrains.compose.resources.ResourceItem(setOf(), "font/SF-Pro-Text-Bold.otf"),
          )
      )

  public val SF_Pro_Text_Regular: FontResource = org.jetbrains.compose.resources.FontResource(
        "font:SF_Pro_Text_Regular",
          setOf(
            org.jetbrains.compose.resources.ResourceItem(setOf(), "font/SF-Pro-Text-Regular.otf"),
          )
      )

  public val SF_Pro_Text_Semibold: FontResource = org.jetbrains.compose.resources.FontResource(
        "font:SF_Pro_Text_Semibold",
          setOf(
            org.jetbrains.compose.resources.ResourceItem(setOf(), "font/SF-Pro-Text-Semibold.otf"),
          )
      )
}

@ExperimentalResourceApi
internal val Res.font.Roboto_Bold: FontResource
  get() = Font0.Roboto_Bold

@ExperimentalResourceApi
internal val Res.font.Roboto_Regular: FontResource
  get() = Font0.Roboto_Regular

@ExperimentalResourceApi
internal val Res.font.SF_Pro_Text_Bold: FontResource
  get() = Font0.SF_Pro_Text_Bold

@ExperimentalResourceApi
internal val Res.font.SF_Pro_Text_Regular: FontResource
  get() = Font0.SF_Pro_Text_Regular

@ExperimentalResourceApi
internal val Res.font.SF_Pro_Text_Semibold: FontResource
  get() = Font0.SF_Pro_Text_Semibold
