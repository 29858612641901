package nay.kirill.miniApp.engclub.core.images

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxScope
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.graphics.DefaultAlpha
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.layout.ContentScale
import io.kamel.core.Resource
import io.kamel.core.utils.File
import io.kamel.image.asyncPainterResource
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.coroutines.launch

@Composable
fun AsyncImage(
        filePath: String,
        contentDescription: String?,
        modifier: Modifier = Modifier,
        alignment: Alignment = Alignment.Center,
        contentScale: ContentScale = ContentScale.Fit,
        alpha: Float = DefaultAlpha,
        colorFilter: ColorFilter? = null,
        onLoading: @Composable (BoxScope.(Float) -> Unit)? = null,
        onFailure: @Composable (BoxScope.(Throwable) -> Unit)? = null,
        contentAlignment: Alignment = Alignment.Center,
) {
    val scope = rememberCoroutineScope()
    var resource: Resource<Painter>? by remember { mutableStateOf(null) }
    var file: File? by remember { mutableStateOf(null) }

    LaunchedEffect(true) {
        scope.launch { file = getResource(filePath) }
    }

    if (file != null) resource = asyncPainterResource(file!!)

    val onSuccess: @Composable (BoxScope.(Painter) -> Unit) = { painter ->
        Image(
                painter,
                contentDescription,
                Modifier.fillMaxSize(),
                alignment,
                contentScale,
                alpha,
                colorFilter
        )
    }
    Box(modifier, contentAlignment) {
        when (resource) {
            null -> if (onLoading != null) onLoading(0F)
            is Resource.Loading -> if (onLoading != null) onLoading((resource as Resource.Loading).progress)
            is Resource.Success -> onSuccess((resource as Resource.Success).value)
            is Resource.Failure -> if (onFailure != null) onFailure((resource as Resource.Failure).exception)
        }
    }
}

private suspend fun getResource(fileResourcePath: String): File {
    val blob = window.fetch(fileResourcePath).await().blob().await()
    return File(
            org.w3c.files.File(
                    arrayOf(blob), fileResourcePath
            )
    )
}