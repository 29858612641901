package nay.kirill.miniApp.engclub.features.wordDetails

import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import cafe.adriel.voyager.core.registry.screenModule
import cafe.adriel.voyager.core.screen.Screen
import nay.kirill.miniApp.engclub.core.viewModel
import nay.kirill.miniApp.engclub.navigation.SharedScreens

val wordDetailsScreenModule = screenModule {
    register<SharedScreens.WordDetails> {
        WordDetailsScreen(wordId = it.wordId)
    }
}

private class WordDetailsScreen(
    private val wordId: String
) : Screen {

    @Composable
    override fun Content() {
        val viewModel = viewModel(WordDetailsViewModel::class)

        LaunchedEffect(key1 = viewModel) {
            viewModel.fetchWordInfo(wordId)
        }

        val state by viewModel.state.collectAsState()
        Text(text = state)
    }

}