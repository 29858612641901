package nay.kirill.miniApp.engclub.core

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisallowComposableCalls
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.remember
import androidx.compose.runtime.saveable.rememberSaveable
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.cancelChildren
import org.koin.core.component.KoinComponent
import org.koin.mp.KoinPlatformTools
import kotlin.coroutines.CoroutineContext
import kotlin.reflect.KClass

abstract class ViewModel : CoroutineScope {

    override val coroutineContext: CoroutineContext = Dispatchers.Default

    fun clear() {
        coroutineContext.cancelChildren()
        onCleared()
    }

    open fun onCleared() = Unit

}

@Composable
fun <TViewModel : ViewModel> viewModel(viewModelClass: KClass<TViewModel>): TViewModel {
    val viewModel = rememberSaveable(viewModelClass) { KoinPlatformTools.defaultContext().get().get<TViewModel>(viewModelClass) }
    DisposableEffect(viewModelClass) {
        onDispose {
            viewModel.clear()
        }
    }

    return viewModel
}