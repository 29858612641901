package nay.kirill.miniApp.engclub.features.wordDetails

import com.kirillNay.telegram.miniapp.webApp.webApp
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import nay.kirill.miniApp.engclub.core.ViewModel
import nay.kirill.miniApp.engclub.domain.repositories.WordRepository
import nay.kirill.miniApp.engclub.navigation.Navigation

class WordDetailsViewModel(
    private val wordRepository: WordRepository
) : ViewModel() {

    private val _state: MutableStateFlow<String> = MutableStateFlow("wordInfo")
    val state: StateFlow<String> = _state.asStateFlow()

    fun fetchWordInfo(id: String) {
        _state.value = "Информация в процессе..."
        launch {
            try {
                val word = wordRepository.getWord(
                    userId = webApp.initDataUnsafe.user?.id.toString(),
                    id = id
                )
                _state.value = "Информация о слове: $word"
            } catch (e: Throwable) {
                _state.value = "Произошла ошибка: $e"
            }
        }

    }

}