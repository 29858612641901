package nay.kirill.miniApp.engclub.theme

import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import com.kirillNay.telegram.miniapp.webApp.webApp

@Composable
fun EnglishClubTheme(
    content: @Composable () -> Unit
) {
    println(webApp.platform)
    val typography = when (webApp.platform) {
        "macos", "ios" -> macosTypography()
        else -> androidWindowsTypography()
    }
    CompositionLocalProvider(
        TelegramTypography provides typography,
        content = content
    )
}