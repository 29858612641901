package nay.kirill.miniApp.engclub.data.models

import kotlinx.serialization.KSerializer
import kotlinx.serialization.SerializationException
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.nullable
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.descriptors.buildClassSerialDescriptor
import kotlinx.serialization.descriptors.element
import kotlinx.serialization.encoding.CompositeDecoder
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.encoding.decodeStructure
import kotlinx.serialization.encoding.encodeStructure

data class WordResponse(
    val id: String,
    val isSaved: Boolean,
    val text: String,
    val transcription: String?,
    val translation: String,
    val image: String?,
    val definition: String,
    val examples: List<String>,
    val meaningsWithSimilarTranslation: List<AlternativeTranslationsResponse>
)

data class AlternativeTranslationsResponse(
    val id: String,
    val translation: String
)

object WordResponseSerializer : KSerializer<WordResponse> {
    override val descriptor: SerialDescriptor = buildClassSerialDescriptor("WordResponse") {
        element<String>("id")
        element<Boolean>("isSaved")
        element<String>("text")
        element<String?>("transcription")
        element<String>("translation")
        element<String?>("image")
        element<String>("definition")
        element<List<String>>("examples")
        element("meaningsWithSimilarTranslation", AlternativeTranslationsResponseSerializer.descriptor)
    }

    override fun deserialize(decoder: Decoder): WordResponse = decoder.decodeStructure(descriptor) {
        var id: String? = null
        var isSaved: Boolean? = null
        var text: String? = null
        var transcription: String? = null
        var translation: String? = null
        var image: String? = null
        var definition: String? = null
        var examples: List<String>? = null
        var meaningsWithSimilarTranslation: List<AlternativeTranslationsResponse>? = null

        var index = 0
        while (decodeElementIndex(descriptor).apply {
                index = this
            } != CompositeDecoder.DECODE_DONE) {
            when (index) {
                0 -> id = decodeStringElement(descriptor, index)
                1 -> isSaved = decodeBooleanElement(descriptor, index)
                2 -> text = decodeStringElement(descriptor, index)
                3 -> transcription = decodeNullableSerializableElement(
                    descriptor,
                    index,
                    String.serializer().nullable
                )

                4 -> translation = decodeStringElement(descriptor, index)
                5 -> image = decodeNullableSerializableElement(
                    descriptor,
                    index,
                    String.serializer().nullable
                )

                6 -> definition = decodeStringElement(descriptor, index)
                7 -> examples = decodeSerializableElement(
                    descriptor,
                    index,
                    ListSerializer(String.serializer())
                )

                8 -> meaningsWithSimilarTranslation =
                    decodeSerializableElement(descriptor, index, ListSerializer(AlternativeTranslationsResponseSerializer))

                else -> throw SerializationException("Element for index $index not found")
            }
        }

        WordResponse(
            id = requireNotNull(id),
            isSaved = requireNotNull(isSaved),
            text = requireNotNull(text),
            transcription = transcription,
            translation = requireNotNull(translation),
            image = image,
            definition = requireNotNull(definition),
            examples = requireNotNull(examples),
            meaningsWithSimilarTranslation = requireNotNull(meaningsWithSimilarTranslation)
        )
    }

    override fun serialize(encoder: Encoder, value: WordResponse) {
        encoder.encodeStructure(descriptor) {
            encodeStringElement(descriptor, 0, value.id)
            encodeBooleanElement(descriptor, 1, value.isSaved)
            encodeStringElement(descriptor, 2, value.text)
            encodeNullableSerializableElement(descriptor, 3, String.serializer().nullable, value.transcription)
            encodeStringElement(descriptor, 4, value.translation)
            encodeNullableSerializableElement(descriptor, 5, String.serializer().nullable, value.image)
            encodeStringElement(descriptor, 6, value.definition)
            encodeSerializableElement(descriptor, 7, ListSerializer(String.serializer()), value.examples)
            encodeSerializableElement(
                descriptor,
                8,
                ListSerializer(AlternativeTranslationsResponseSerializer),
                value.meaningsWithSimilarTranslation
            )

        }
    }

}

object AlternativeTranslationsResponseSerializer : KSerializer<AlternativeTranslationsResponse> {
    override val descriptor: SerialDescriptor =
        buildClassSerialDescriptor("AlternativeTranslationsResponse") {
            element<String>("id")
            element<String>("translation")
        }

    override fun deserialize(decoder: Decoder): AlternativeTranslationsResponse = decoder.decodeStructure(descriptor) {
        var id: String? = null
        var translation: String? = null

        var index = 0
        while (decodeElementIndex(descriptor).apply { index = this } != CompositeDecoder.DECODE_DONE) {
            when (index) {
                0 -> id = decodeStringElement(descriptor, index)
                1 -> translation = decodeStringElement(descriptor, index)
                else -> throw SerializationException("Element for index $index not found")
            }
        }

        AlternativeTranslationsResponse(
            requireNotNull(id),
            requireNotNull(translation)
        )
    }

    override fun serialize(encoder: Encoder, value: AlternativeTranslationsResponse) {
        encoder.encodeStructure(descriptor) {
            encodeStringElement(descriptor, 0, value.id)
            encodeStringElement(descriptor, 1, value.translation)
        }
    }
}