package nay.kirill.miniApp.engclub.res

object Res {

    val string: StringRes = StringRes()

}

class StringRes {

    val pageNotFoundTitle: String = "Страница не найдена"

    val pageNotFoundButton: String = "Начать учить слова"

}